/* import __COLOCATED_TEMPLATE__ from './phone-overview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type FinVoiceService from 'embercom/services/fin-voice-service';
import type RouterService from '@ember/routing/router-service';
import { registerDestructor } from '@ember/destroyable';

export default class FinAIAgentDeployPhoneOverview extends Component {
  readonly VOICE_PLAYGROUND_TAB = 'voice-playground';
  readonly DEPLOY_TAB = 'deploy';

  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare finVoiceService: FinVoiceService;
  @service declare router: RouterService;

  @tracked activeTab: typeof this.VOICE_PLAYGROUND_TAB | typeof this.DEPLOY_TAB = this
    .canUseFinVoicePlayground
    ? this.VOICE_PLAYGROUND_TAB
    : this.DEPLOY_TAB;

  @tracked isSideMenuOpen =
    this.activeTab === this.VOICE_PLAYGROUND_TAB && this.canUseFinVoicePlaygroundDev;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this._loadCorrectTab();

    this.router.on('routeDidChange', this._handleRouteChange);

    registerDestructor(this, () => {
      this.router.off('routeDidChange', this._handleRouteChange);
    });
  }

  get canUseFinVoicePlayground() {
    return this.appService.app.canUseFinVoicePlayground;
  }

  get canSetupFinVoicePhoneEasySetup() {
    return this.appService.app.canSetupFinVoicePhoneEasySetup;
  }

  get canUseFinVoicePlaygroundDev() {
    return this.appService.app.canUseFinVoicePlaygroundDev;
  }

  get fullyEnabled() {
    return this.canUseFinVoicePlayground && this.canSetupFinVoicePhoneEasySetup;
  }

  get showControlsInHeader() {
    return this.canUseFinVoicePlayground && !this.canSetupFinVoicePhoneEasySetup;
  }

  get showSideMenu() {
    return this.activeTab === this.VOICE_PLAYGROUND_TAB && this.isSideMenuOpen;
  }

  get showSideMenuToggle() {
    return this.activeTab === this.VOICE_PLAYGROUND_TAB;
  }

  get sessions() {
    return this.finVoiceService.sessions;
  }

  get hasSessions() {
    return !!this.sessions?.length;
  }

  get verboseLogsEnabled() {
    return this.finVoiceService.verboseLogsEnabled;
  }

  get title() {
    return !this.fullyEnabled && this.canUseFinVoicePlayground
      ? this.intl.t('ai-agent.voice-playground.fin-voice')
      : this.intl.t('ai-agent.new-submenu.phone');
  }

  @action
  _handleRouteChange() {
    this._loadCorrectTab();
  }

  _loadCorrectTab() {
    if (!this.fullyEnabled) {
      return;
    }

    let currentTabParam = this.router.currentRoute?.queryParams?.tab;

    if (!currentTabParam) {
      return;
    }

    if (currentTabParam === this.VOICE_PLAYGROUND_TAB) {
      this.activeTab = this.VOICE_PLAYGROUND_TAB;
    } else if (currentTabParam === this.DEPLOY_TAB) {
      this.activeTab = this.DEPLOY_TAB;
    }
  }

  @action
  closeSideMenu() {
    this.isSideMenuOpen = false;
  }

  @action
  openSideMenu() {
    this.isSideMenuOpen = true;
  }

  @action
  reportIssue() {
    return this.finVoiceService.reportIssue();
  }

  @action
  copyLatestSessionId() {
    return this.finVoiceService.copyLatestSessionId();
  }

  @action
  toggleVerboseLogsEnabled() {
    this.finVoiceService.verboseLogsEnabled = !this.finVoiceService.verboseLogsEnabled;
  }

  @action
  changeTab(tab: typeof this.VOICE_PLAYGROUND_TAB | typeof this.DEPLOY_TAB) {
    this.activeTab = tab;
    this.router.transitionTo({ queryParams: { tab } });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Deploy::PhoneOverview': typeof FinAIAgentDeployPhoneOverview;
  }
}
