/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type { FinVoiceVadSettings } from 'embercom/objects/ai-agent/fin-voice/fin-voice-server-events';
import type { AudioDeviceSettings, WebsocketState } from 'embercom/services/fin-voice-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { FinVoiceVoice } from 'embercom/objects/ai-agent/fin-voice/fin-voice-voice-options';
import { FIN_VOICE_11LABS_VOICES } from 'embercom/objects/ai-agent/fin-voice/fin-voice-voice-options';
import type { FinVoicePipeline } from 'embercom/objects/ai-agent/fin-voice/fin-voice-pipeline-options';
import { FIN_VOICE_PIPELINE_OPTIONS } from 'embercom/objects/ai-agent/fin-voice/fin-voice-pipeline-options';

export interface SettingsArgs {
  connectionState: WebsocketState;
  vadSettings: FinVoiceVadSettings;
  selectedVoice: FinVoiceVoice;
  selectedPipeline: FinVoicePipeline;
  audioDeviceSettings: AudioDeviceSettings;
  canUseFinVoicePlaygroundDev: boolean;
  updateVadSetting: (key: keyof FinVoiceVadSettings, value: number) => void;
  updateAudioDeviceSetting: (key: keyof AudioDeviceSettings, value: boolean) => void;
  updateVoice: (voice: FinVoiceVoice) => void;
  updatePipeline: (pipeline: FinVoicePipeline) => void;
}

interface Signature {
  Args: SettingsArgs;
  Element: HTMLElement;
}

export default class Settings extends Component<Signature> {
  @tracked threshold = this.args.vadSettings.threshold;
  @tracked prefixPaddingMs = this.args.vadSettings.prefixPaddingMs;
  @tracked silenceDurationMs = this.args.vadSettings.silenceDurationMs;

  @tracked selectedVoice = this.args.selectedVoice;
  @tracked selectedPipeline = this.args.selectedPipeline;
  @tracked echoCancellation = this.args.audioDeviceSettings.echoCancellation;
  @tracked noiseSuppression = this.args.audioDeviceSettings.noiseSuppression;
  @tracked voiceIsolation = this.args.audioDeviceSettings.voiceIsolation;

  get voiceOptions() {
    return FIN_VOICE_11LABS_VOICES;
  }

  get pipelineOptions() {
    return FIN_VOICE_PIPELINE_OPTIONS;
  }

  get isSessionConnected() {
    return this.args.connectionState !== 'disconnected';
  }

  @action onVoiceChange(event: FinVoiceVoice) {
    this.selectedVoice = event;
    this.args.updateVoice(this.selectedVoice);
  }

  @action onPipelineChange(event: FinVoicePipeline) {
    this.selectedPipeline = event;
    this.args.updatePipeline(this.selectedPipeline);
  }

  @action onThresholdChange(event: Event) {
    this.threshold = parseFloat((event.target as HTMLInputElement).value);
    this.args.updateVadSetting('threshold', this.threshold);
  }

  @action onPrefixPaddingMsChange(event: Event) {
    this.prefixPaddingMs = parseFloat((event.target as HTMLInputElement).value);
    this.args.updateVadSetting('prefixPaddingMs', this.prefixPaddingMs);
  }

  @action onSilenceDurationMsChange(event: Event) {
    this.silenceDurationMs = parseFloat((event.target as HTMLInputElement).value);
    this.args.updateVadSetting('silenceDurationMs', this.silenceDurationMs);
  }

  @action toggleEchoCancellation() {
    this.echoCancellation = !this.echoCancellation;
    this.args.updateAudioDeviceSetting('echoCancellation', this.echoCancellation);
  }

  @action toggleNoiseSuppression() {
    this.noiseSuppression = !this.noiseSuppression;
    this.args.updateAudioDeviceSetting('noiseSuppression', this.noiseSuppression);
  }

  @action toggleVoiceIsolation() {
    this.voiceIsolation = !this.voiceIsolation;
    this.args.updateAudioDeviceSetting('voiceIsolation', this.voiceIsolation);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::Settings': typeof Settings;
  }
}
